import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`These guidelines will help you determine when assistive tools should output a message.`}</p>
    <h2>{`Guidelines`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Always: Announce location changes`}</strong>{` — Help users understand where they are on a page. Maintain positional awareness.`}</li>
      <li parentName="ul"><strong parentName="li">{`Always: Announce results of user actions`}</strong>{` — Give feedback about the success or failure directly following a user action.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sometimes: Announce other changes`}</strong>{` — Explore case-by-case when changes are disconnected from the user’s place and the success or failure of the user’s actions. For example:`}
        <ul parentName="li">
          <li parentName="ul">{`Distracting change announcements: When other users add issue comments; when presence indicators appear`}</li>
          <li parentName="ul">{`Essential change announcements: When log lines stream in on the GitHub Actions workflow run page`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Additional resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/github/accessibility/blob/main/docs/deep-dive-notes/2022-03-02-page-transitions.md"
        }}>{`Deep Dive: Page and Navigation transitions, Dynamic Content, and SPAs`}</a>{` (only accessible to GitHub staff)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/github/accessibility/blob/main/docs/deep-dive-notes/2021-11-19-save-patterns-and-status-messages.md"
        }}>{`Deep Dive: Save Patterns & Status Messages`}</a>{` (only accessible to GitHub staff)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      